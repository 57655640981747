(function () {
    $('.show-tabs').on('click', function(e){
        e.stopPropagation();
        $('.nav-tabs li:not(".active")').toggle();
    });

    $('.nav-tabs li:not("active")').on('click', function(){
        $('.nav-tabs li').css('display', '');
        $(this).show();
    })
} ());